var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"8"}},[_c('div',{staticClass:"w-100 text-center"},[(!_vm.form.avatar)?_c('img',{staticClass:"m-auto cursor-pointer",attrs:{"src":require("@/assets/images/profile/avatars/bg_color.png"),"alt":""}}):_c('img',{staticClass:"m-auto cursor-pointer",attrs:{"src":_vm.form.avatar,"alt":""}})]),_c('div',[_c('b-form',{staticClass:"auth-register-form mt-2",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('strong',{staticStyle:{"padding-bottom":"10px"}},[_vm._v("Personal Information")]),_c('validation-observer',{ref:"registerForm",staticClass:"w-100",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',{staticClass:"w-100 d-flex mt-1"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"First name","label-for":"fname"}},[_c('validation-provider',{attrs:{"name":"first name","vid":"fname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('feather-icon',{staticStyle:{"color":"#e84185"},attrs:{"icon":"UserIcon","size":"16"}}),_c('b-form-input',{staticClass:"custom-form-control",attrs:{"id":"fname","name":"fname","placeholder":"First Name*","autocomplete":"off","autocorrect":"off","autocapitalize":"off","disabled":!_vm.editable,"state":errors.length > 0 ? false : null},model:{value:(_vm.form.fname),callback:function ($$v) {_vm.$set(_vm.form, "fname", $$v)},expression:"form.fname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Last name","label-for":"lname"}},[_c('validation-provider',{attrs:{"name":"last name","vid":"lname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('feather-icon',{staticStyle:{"color":"#e84185"},attrs:{"icon":"UserIcon","size":"16"}}),_c('b-form-input',{staticClass:"custom-form-control",attrs:{"id":"lname","name":"lname","placeholder":"Last Name*","autocomplete":"off","autocorrect":"off","autocapitalize":"off","disabled":!_vm.editable,"state":errors.length > 0 ? false : null},model:{value:(_vm.form.lname),callback:function ($$v) {_vm.$set(_vm.form, "lname", $$v)},expression:"form.lname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email Address*","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","vid":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('feather-icon',{staticStyle:{"color":"#e84185"},attrs:{"icon":"MailIcon","size":"16"}}),_c('b-form-input',{staticClass:"custom-form-control",attrs:{"id":"email","name":"email","placeholder":"Last Name*","autocomplete":"off","autocorrect":"off","autocapitalize":"off","disabled":"","state":errors.length > 0 ? false : null},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('strong',{staticStyle:{"padding-bottom":"10px"}},[_vm._v("School Information")])]),_c('b-col',{staticClass:"mt-1",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label-for":"year_group"}},[_c('validation-provider',{attrs:{"name":"year_group","vid":"year_group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('feather-icon',{staticStyle:{"color":"#e84185"},attrs:{"icon":"UserIcon","size":"16"}}),_c('b-form-input',{staticClass:"custom-form-control",attrs:{"id":"year_group","name":"year_group","placeholder":"Year group","autocomplete":"off","autocorrect":"off","autocapitalize":"off","disabled":!_vm.editable,"state":errors.length > 0 ? false : null},model:{value:(_vm.form.year_group),callback:function ($$v) {_vm.$set(_vm.form, "year_group", $$v)},expression:"form.year_group"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('button',{staticClass:"btn btn-primary rounded-pill shadow-none mx-auto mt-3 px-5",attrs:{"type":"submit","disabled":invalid || _vm.load}},[(_vm.load)?_c('center',[_c('b-spinner',{attrs:{"variant":"light","label":"Spinning"}})],1):_c('span',[_vm._v(" Submit ")])],1)],1)]}}])})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }