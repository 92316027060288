<template>
    <div>
        <b-card class="p-2 ">
            <center v-if="load">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
            </center>
            <div v-else>
                <b-row class="pt-2">
                    <b-col  cols="6" class="mb-2 d-flex">
                        <h5>Certificate Name : </h5>
                        <h5 class="ml-1" v-if="item.name">{{item.name}}</h5>
                    </b-col>
                    <b-col  cols="6" class="mb-2 d-flex">
                        <h5>Description :  </h5>
                        <span class="ml-1" v-if="item.description">{{item.description}}</span>
                    </b-col>
                    <b-col  cols="6" class="mb-2 d-flex">
                        <h5>Author : </h5>
                        <h5 class="ml-1" v-if="item.author">{{item.author}}</h5>
                    </b-col>
                    <b-col  cols="6" class="mb-2 d-flex">
                        <h5>Author Title :  </h5>
                        <span class="ml-1" v-if="item.author_title">{{item.author_title}}</span>
                    </b-col>
                    <b-col  cols="6" class="mb-2 d-flex">
                        <h5>Author Name Company : </h5>
                        <h5 class="ml-1" v-if="item.HL_author">{{item.HL_author}}</h5>
                    </b-col>
                    <b-col  cols="6" class="mb-2 d-flex">
                        <h5>Author Title Company :  </h5>
                        <span class="ml-1" v-if="item.HL_author_title">{{item.HL_author_title}}</span>
                    </b-col>
                    <b-col  cols="6" class="mb-2 ">
                        <h5>modules : </h5>
                        <div v-for="(module,index) in item.modules" :key="index">
                            <h5 class="ml-1"> - {{module.name}}</h5>
                        </div>
                    </b-col>
                    <b-col  cols="6" class="mb-2 ">
                        <h5>Logo :  </h5> 
                        <span class="ml-1" v-if="item.certificate_logo">
                            <img :src="item.certificate_logo" alt width="100%" />
                        </span>
                    </b-col>
                    
                </b-row>
            </div>

            
        </b-card>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
// eslint-disable-next-line
import {
    BSpinner,
  BCard,
  BCol,
  BRow,
  BFormFile,
  BForm,
  BFormGroup,
  BButton,
  BFormInput,
  BFormDatepicker,
  BModal,
  BBadge,
  BTab,
  BTabs,
  BLink,
  BDropdown,
  BDropdownItem,
  BFormTextarea,
  validate,
} from "bootstrap-vue";

export default {
    name: "show",
    components: {
        BSpinner,
        BCard,
        BCol,
        BRow,
        BTab,
        BTabs,
        BLink,
        BFormFile,
        BForm,
        BFormGroup,
        BButton,
        BFormInput,
        BFormDatepicker,
        BModal,
        BBadge,
        BTab,
        BTabs,
        BLink,
        BDropdown,
        BDropdownItem,
        BFormTextarea,
    },
    computed:{
    ...mapGetters({
        item: "bulk_certificates/item",
        load: "bulk_certificates/load",
    }),
    id(){
      return this.$route.params.id ? this.$route.params.id : null
    },
    
  },
  data(){
    return{
        bubbleOption: {
            modules: {
                toolbar: false,
            },
            theme: 'bubble',
        },
    }
  },
  methods:{
      init(){
            this.$store.dispatch('bulk_certificates/get', this.id).then(_=>{
            })
        },  
  },
  mounted(){
      this.init()
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/quill.scss';
</style>