import { render, staticRenderFns } from "./OpenQuestions.vue?vue&type=template&id=ebacc64a&scoped=true"
import script from "./OpenQuestions.vue?vue&type=script&lang=js"
export * from "./OpenQuestions.vue?vue&type=script&lang=js"
import style0 from "./OpenQuestions.vue?vue&type=style&index=0&id=ebacc64a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebacc64a",
  null
  
)

export default component.exports