import Courses from '@/api/courses/courses.js';
import fileDownload from "js-file-download";
import router from "@/router";

const courses = new Courses();

const coursesModule = {
  namespaced: true,
  state: {
    items: [],
    item: null,
    lookups: [],
    total: 0,
    load: false,
    loadItem:false,
  },
  getters: {
    items(state) {
      return state.items;
    },
    item(state) {
      return state.item;
    },
    load(state) {
      return state.load;
    },
    loadItem(state) {
      return state.loadItem;
    },
    total(state) {
      return state.total;
    },
    lookups(state) {
      return state.lookups;
    },
  },
  mutations: {
    SET_ITEMS: (state, courses) => {
      state.items = courses;
    },
    SET_TOTAL_COURSES: (state, total) => {
      state.total = total;
    },
    SET_ITEMS_LOAD: (state, load) => {
      state.load = load;
    },
    SET_LOAD_ITEM: (state, loadItem) => {
      state.loadItem = loadItem;
    },
    SET_ITEM: (state, school) => {
      state.item = school;
    },
    SET_LOOKUPS(state, lookups) {
      state.lookups = lookups;
    },
  },
  actions: {
    list({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('SET_ITEMS_LOAD', true);
        courses.list(payload).then(response => {
          commit('SET_ITEMS', response);
          commit('SET_ITEMS_LOAD', false);
          resolve();
        });
      });
    },
    put({ commit }, payload) {
      return new Promise((resolve, reject) => {
        courses
          .put(payload.id,payload.query)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    get({ commit , dispatch }, id) {
      return new Promise((resolve, reject) => {
        commit('SET_LOAD_ITEM', true);
        courses.get(id)
          .then(response => {
            commit('SET_ITEM', response);
            commit('SET_LOAD_ITEM', false);
            dispatch('schools/updateBreadVale', response.name, { root: true })
            resolve(response);
          })
          .catch(error => {
            reject(error);
            commit('SET_LOAD_ITEM', false);
          });
      });
    },
    lookups({ commit }) {
      return new Promise((resolve, reject) => {
        courses.lookups().then(response => {
          commit('SET_LOOKUPS', response.courses);
          resolve();
        });
      });
    }, 
    remove({ commit }, id) {
      return new Promise((resolve, reject) => {
        courses
          .remove(id)
          .then(response => {
            
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    restore({ commit }, id) {
      return new Promise((resolve, reject) => {
        courses
          .restore(id)
          .then(response => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    hardRemove({ commit }, id) {
      return new Promise((resolve, reject) => {
        courses
          .hardRemove(id)
          .then(response => {
            
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export default coursesModule;
