<template>
<!-- Error page-->
<div class="misc-wrapper">
    <b-link style="position: absolute; top:0; left: 0; max-width: 200px" to="dashboard">
    <img src="../../assets/images/logo/logo.png" alt="" class="logo w-100">
    </b-link>
    <b-link class="brand-logo">
    <!-- <vuexy-logo /> -->
    <!-- <b-link style="position: absolute; top:0; right: 0">
        <img src="../../assets/images/logo/logo.png" alt="" class="logo">
    </b-link> -->
    </b-link>

    <div class="misc-inner p-2 p-sm-3 ">
    <div class="w-100 text-center">
        <h2 class="mb-1 text-muted">
            This account has been deactivated please contact your school admin
        </h2>
        <p class="mb-2 text-light">
        Oops! 😖 you can't access it now.
        </p>

        <!-- <b-button
        variant="primary"
        style="background-color: #E84185 !important; border: none"
        class="mb-2 btn-sm-block"
        :to="{path:'/'}"
        >
        Back to home
        </b-button> -->

        <!-- image -->
        <!-- <b-img
        fluid
        :src="imgUrl"
        alt="Error page"
        /> -->
    </div>
    </div>
</div>
<!-- / Error page-->
  </template>
  
<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

export default {
components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
},
data() {
    return {
    downImg: require('@/assets/images/pages/error.svg'),
    }
},
computed: {
    imgUrl() {
    if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
    }
    return this.downImg
    },
},
}
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-misc.scss';

    .misc-wrapper{
    background: url('../../assets/images/banner/dark_bakground.png') no-repeat;
    background-size: cover;
    }
</style>
