import Resource from '@/api/resource';
import request from '@/utils/request';
import store from "@/store/index";

class Topics extends Resource {
    constructor() {
        super('topics');
    }

    list(query) {
        return request({
            url: `${this.uri}/list_topics`,
            method: 'post',
            data: query,
        })
    }

    put(query) {
        return request({
            url: `/${this.uri}`,
            headers: { "Content-Type": "multipart/form-data" },
            method: 'post',
            data: query,
            onUploadProgress: function (progressEvent) {
                store.commit('app/SET_UPLOAD_PROGRESS', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))
            }.bind(store),
        })
    }
    update(query) {
          return request({
              url: `/${this.uri}/update_topic`,
              headers: { "Content-Type": "multipart/form-data" },
              method: 'post',
              data: query,
              onUploadProgress: function (progressEvent) {
                store.commit('app/SET_UPLOAD_PROGRESS', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))
              }.bind(store),
          })
      }

    get(id) {
        return request({
            url: `/${this.uri}/show`,
            method: 'post',
            data: {id: id}
        })
    }

    subTopicsLookups(){
        return request({
            url: '/lookups/types_lookups',
            method: 'get'
        })
    }


    order(query) {
        return request({
            url: `/${this.uri}/resorting`,
            method: 'post',
            data: query,
        })
    }
    watched(query) {
        return request({
            url: `/${this.uri}/watched`,
            method: 'post',
            data: query,
        })
    }

}

export {Topics as default};
