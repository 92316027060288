<template>
    
    <div >
      
      <center v-if="load">
        <b-spinner variant="primary" label="Spinning"></b-spinner>
      </center>
        <b-row v-else >
            <b-col md="12" class="mb-2" v-if="finishData.result >= 50 || (lessonData.type == 1 && lessonData.subTopics.length == 0) || ((lessonData.type == 1 && lessonData.subTopics.length == 1 && lessonData.subTopics[0].MCQ.length == 0))" >
                <b-card
                  :class="$store.state.appConfig.layout.skin == 'dark' ? 'bg-custom-card-dark' : ''"
                >
                  <!-- <div class="d-flex justify-content-end">
                    <vue-apex-charts
                        type="radialBar"
                        height="120"
                        :options="goalOverviewRadialBarProgress.chartOptions"
                        :series="goalOverviewRadialBarProgress.series"
                    />
                  </div> -->
                    <b-row class="pb-3 pt-1">
                        <b-col md="2"></b-col>
                        <b-col md="8" class="d-flex justify-content-center align-items-center">
                            <div>
                                <div class="mb-2 text-center">
                                    <img src="@/assets/images/learning/lessons/achive.png" alt="">
                                </div>
                                <div class="text-center mb-0">
                                    <h1 :class="$store.state.appConfig.layout.skin == 'dark' ? 'font-weight-bolder text-white mb-1' : 'font-weight-bolder mb-1'">Well Done!</h1>
                                    <div v-if="finishData.type == 1 || finishData.type == 2 ">
                                      <h4 :class="$store.state.appConfig.layout.skin == 'dark' ? 'font-weight-light text-white mb-1': 'font-weight-light mb-1'" v-if="lessonData.subTopics.length > 0 && lessonData.subTopics[0].MCQ.length > 0">You have passed the quiz!</h4>
                                      <h4 :class="$store.state.appConfig.layout.skin == 'dark' ? 'font-weight-light text-white mb-1': 'font-weight-light mb-1'" v-if="lessonData.subTopics.length > 0 && lessonData.subTopics[0].MCQ.length > 0" >You scored:</h4>
                                    </div>
                                </div>
                                <div  >
                                  
                                    <div v-if="lessonData.subTopics.length > 0 && lessonData.subTopics[0].MCQ.length > 0 && (finishData.type == 1 || finishData.type == 2 )" >
                                        <vue-apex-charts 
                                            type="radialBar"
                                            height="245"
                                            :options="goalOverviewRadialBar.chartOptions"
                                            :series="goalOverviewRadialBar.series"
                                        />
                                    </div>
                                  
                                    <div>
                                        <b-button
                                            variant="outline-primary"
                                            class="rounded-pill mt-2 mr-1"
                                            type="submit"
                                            @click="$router.push({name:'module_introduction',params:{id:finishData.module_id}})"
                                        >
                                            Back To Map
                                        </b-button>
                                        <b-button v-if="finishData.next_id != null"
                                            variant="primary"
                                            :class="'rounded-pill mt-2'"
                                            type="submit"

                                            @click="$router.push({name:'lesson',params:{id:finishData.next_id}})"
                                        >
                                            Next Lesson
                                        </b-button>
                                        <b-button v-else
                                            variant="primary"
                                            :class="'rounded-pill mt-2'"
                                            type="submit"

                                            @click="$router.push({name:'student_learning'})"
                                        >
                                          Finish
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                        <b-col md="2"></b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col md="12" v-else>
                <b-card
                  :class="$store.state.appConfig.layout.skin == 'dark' ? 'bg-custom-card-dark' : ''"
                >
                    <b-row class="py-4">
                        <b-col md="2"></b-col>
                        <b-col md="8" class="d-flex justify-content-center align-items-center">
                            <div>
                                <div class="mb-2 text-center">
                                    <img src="@/assets/images/learning/lessons/failed.png" alt="">
                                </div>
                                <div class="text-center mb-0" v-if="finishData.type == 1 || finishData.type == 2 ">
                                  
                                    <h1 :class="$store.state.appConfig.layout.skin == 'dark' ? 'font-weight-bolder text-white mb-1': 'font-weight-bolder mb-1'">Don’t give up!</h1>
                                    <h4 :class="$store.state.appConfig.layout.skin == 'dark' ? 'font-weight-light text-white mb-1' : 'font-weight-light mb-1'">Unfortunately, you have failed the quiz</h4>
                                    <h4 :class="$store.state.appConfig.layout.skin == 'dark' ? 'font-weight-light text-white mb-1' : 'font-weight-light mb-1'">You scored:</h4>
                                </div>
                                <div class="text-center mb-0" v-else>
                                  <h1 :class="$store.state.appConfig.layout.skin == 'dark' ? 'font-weight-bolder text-white mb-1' : 'font-weight-bolder mb-1'">Well Done!</h1>
                                </div>
                                <div class="text-center">
                                    <div v-if="finishData.type == 1 || finishData.type == 2 ">
                                        <vue-apex-charts
                                            type="radialBar"
                                            height="245"
                                            :options="goalOverviewRadialBar2.chartOptions"
                                            :series="goalOverviewRadialBar2.series"
                                        />
                                    </div>
                                    <div>
                                        <b-button
                                            variant="outline-primary"
                                            class="rounded-pill mt-2 mr-1"
                                            type="submit"
                                            @click="$router.push({name:'lesson',params:{id:id}})"
                                        >
                                          Try Again
                                        </b-button>
                                        <b-button v-if="finishData.next_id != null"
                                            variant="primary"
                                            :class="'rounded-pill mt-2'"
                                            type="submit"

                                            @click="$router.push({name:'lesson',params:{id:finishData.next_id}})"
                                        >
                                            Next Lesson
                                        </b-button>
                                        <b-button v-else
                                            variant="primary"
                                            :class="'rounded-pill mt-2'"
                                            type="submit"

                                            @click="$router.push({name:'student_learning'})"
                                        >
                                            Finish
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                        <b-col md="2"></b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import {
  BCard, 
  BRow, 
  BCol, 
  BCardText, 
  BButton,
  BLink, 
  BListGroup, 
  BListGroupItem, 
  BCardTitle, 
  BCardBody,
  BTab, BTabs,
  BAlert,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from "vuex";
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
export default {
  components: {
    BCard, 
    BRow, 
    BCol, 
    BCardText, 
    BButton,
    BLink, 
    BListGroup, 
    BListGroupItem, 
    BCardTitle, 
    BCardBody,
    BTab, BTabs,
    VueApexCharts,
    BAlert,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  computed:{
    ...mapGetters({
      finishData: "lessonPlans/finishData",
      loadFinish: "lessonPlans/loadFinish",
      lessonData: "topics/item",
      load: "lessonPlans/load",
    }),
    id(){
      return this.$route.params.id ? this.$route.params.id : null
    },
    goalOverviewRadialBar(){
      return {
        series: [this.finishData.result],
        chartOptions: {
          chart: {
            height: 80,
            type: 'radialBar',
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
                hollow: {
                margin: 0,  
                size: '65%',
                background: this.$store.state.appConfig.layout.skin == 'dark' ? '#000' : '#fff',
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: 'front',
                dropShadow: {
                  enabled: true,
                  top: 3,
                  left: 0,
                  blur: 5,
                  opacity: 0.24
                }
              },
              track: {
                background: this.$store.state.appConfig.layout.skin == 'dark' ? '#000' : '#fff',
                strokeWidth: '70%',
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  bottom: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.55
                }
              },
          
              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: false,
                  color: '#fff',
                  fontSize: '17px'
                },
                value: {
                  formatter: function(val) {
                    return parseInt(val)+'%';
                  },
                  color: this.$store.state.appConfig.layout.skin == 'dark' ? '#fff' : '#111',
                  fontSize: '36px',
                  show: true,
                }
              }
            }
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 1,
              gradientToColors: ['#E84185'],
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            }
          },
          stroke: {
            lineCap: 'round'
          },
          labels: ['Percent'],
        },
      }
    },
    goalOverviewRadialBar2(){
      return {
        series: [this.finishData.result],
          chartOptions: {
            chart: {
              height: 350,
              type: 'radialBar',
              toolbar: {
                show: false
              }
            },
            plotOptions: {
              radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                  margin: 0,
                  size: '65%',
                  background: this.$store.state.appConfig.layout.skin == 'dark' ? '#000' : '#fff',
                  image: undefined,
                  imageOffsetX: 0,
                  imageOffsetY: 0,
                  position: 'front',
                  dropShadow: {
                    enabled: true,
                    top: 3,
                    left: 0,
                    blur: 5,
                    opacity: 0.24
                  }
                },
                track: {
                  background: this.$store.state.appConfig.layout.skin == 'dark' ? '#000' : '#fff',
                  strokeWidth: '70%',
                  margin: 0, // margin is in pixels
                  dropShadow: {
                    enabled: true,
                    bottom: -3,
                    left: 0,
                    blur: 4,
                    opacity: 0.55
                  }
                },
            
                dataLabels: {
                  show: true,
                  name: {
                    offsetY: -10,
                    show: false,
                    color: '#888',
                    fontSize: '17px'
                  },
                  value: {
                    formatter: function(val) {
                      return parseInt(val)+'%';
                    },
                    color: '#ccc',
                    fontSize: '36px',
                    show: true,
                  }
                }
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'dark',
                type: 'horizontal',
                shadeIntensity: 1,
                gradientToColors: ['#E84185'],
                inverseColors: false,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 100]
              }
            },
            stroke: {
              lineCap: 'round'
            },
            labels: ['Percent'],
          },
      }
    }

  },
  data() {
    return {
      goal_overview: {},
    }
  },
  mounted(){
    if(this.id){
      this.$store.dispatch('topics/get', this.id).then(_=>{
      })
      this.$store.dispatch('lessonPlans/finishTopic', {topic_id:this.id}).then((_)=>{
      })
    }
  }
}
</script>
<style lang="">
    
</style>