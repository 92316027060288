<template>
  <div>
      <b-row class="m-0" style="position: relative;"> 
          <b-tabs :class="$store.state.appConfig.layout.skin === 'dark' ? 'dark-tabs w-100' : 'w-100'" content-class="mt-2 w-100 px-2" lazy>
            <b-tab title="Overview" active>
                <!-- <b-alert show variant="danger" class="p-2 d-flex">
                  
                  <span class="pr-2">
                    <feather-icon
                      icon="AlertCircleIcon"
                      size="40"
                    />
                  </span>
                  <span>
                    <p>It looks like your class is off schedule.</p>
                    <small>Follow up with your students to make sure they aren’t falling behind.</small>
                  </span>
                </b-alert> -->
                <b-row class="mt-2">
                  <b-col
                    md="7"
                  >
                  
                  
                  <center v-if="loadProg">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                  </center> 
                    <b-card v-else :class="$store.state.appConfig.layout.skin === 'dark' ? 'border-dark rounded-lg':'rounded-lg'">
                      <h5 class="font-weight-bolder">Live in-class student progress</h5>
                      <center v-if="error" >
                          <h3 class="mt-2">{{ error_msg }}</h3>
                        </center>
                      <b-row v-if="progress">
                        
                        <b-col
                          md="6"
                        >
                          <b-card :style="$store.state.appConfig.layout.skin == 'dark' ? 'background-color: #0b0b0b; border: 2px solid #4d4d4d80' : 'background-color: #f8f8f8'" class="mt-2">
                            <b-row>
                              <b-col
                                md="4"
                              >
                                <div class="py-2 text-center rounded-lg" style="background-color: #F9D2A2">
                                  <span class="rounded-circle mb-1" :style="$store.state.appConfig.layout.skin == 'dark' ? 'background-color: #0b0b0b; padding: 10px 15px 10px 15px' : 'background-color: #fff; padding: 10px 15px 10px 15px' ">{{ progress.todo }}</span>
                                  <span :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-muted mb-0 mt-1 d-block font-weight-bolder' : 'mb-0 mt-1 d-block font-weight-bolder'">To Do</span>
                                </div>
                              </b-col>
                              <b-col
                                md="4"
                              >
                                <div class="py-2 text-center rounded-lg" style="background-color: #F6B3CE">
                                  <span class="rounded-circle mb-1" :style="$store.state.appConfig.layout.skin == 'dark' ? 'background-color: #0b0b0b; padding: 10px 13px 10px 13px' : 'background-color: #fff; padding: 10px 13px 10px 13px' ">{{ progress.inprogress }}</span>
                                  <small :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-muted mb-0 mt-1 d-block font-weight-bolder' : 'mb-0 mt-1 d-block font-weight-bolder'">In progress</small>
                                </div>
                              </b-col>
                              <b-col
                                md="4"
                              >
                                <div class="py-2 text-center rounded-lg" style="background-color: #B3DEB7">
                                  <span class="rounded-circle mb-1" :style="$store.state.appConfig.layout.skin == 'dark' ?'background-color: #0b0b0b; padding: 10px 15px 10px 15px' : 'background-color: #fff; padding: 10px 15px 10px 15px' ">{{ progress.done }}</span>
                                  <span :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-muted mb-0 mt-1 d-block font-weight-bolder' : 'mb-0 mt-1 d-block font-weight-bolder'">Done</span>
                                </div>
                              </b-col>
                            </b-row>
                            <hr>
                            <b-row class="mb-1">
                              <b-col md="9">
                                Class curriculum progress:
                              </b-col>
                              <b-col md="3">
                                <span style="background-color: #B3DEB7; color: #000" class="rounded px-1">
                                    {{progress.curriculum_progress}}%
                                </span>
                              </b-col>
                            </b-row>
                            <b-row class="mb-1">
                              <b-col md="9">
                                Class core competencies progress:
                              </b-col>
                              <b-col md="3">
                                <span style="background-color: #B3DEB7; color: #000" class="rounded px-1">
                                    {{progress.core_competencies_progress}}%
                                </span>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col md="9">
                                Class homework progress:
                              </b-col>
                              <b-col md="3">
                                <span style="background-color: #B3DEB7; color: #000" class="rounded px-1">
                                    {{progress.homework_progress}}%
                                </span>
                              </b-col>
                            </b-row>
                          </b-card>
                        </b-col>
                        <b-col
                          md="6"
                        >
                          <vue-apex-charts
                            type="radialBar"
                            height="400"
                            :options="apexChatData.radialBarChart.chartOptions"
                            :series="[progress.done_per,progress.inprogress_per,progress.todo_per]"
                          />
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>
                  <b-col
                    md="5"
                  >
                    <card-advance-employee-task />  
                  </b-col>
                </b-row>
            </b-tab>
            <!-- <b-tab title="Lesson Plans">
                <b-row class="w-100 h-100 d-flex justify-content-center align-items-center pt-5">
                  <div class="text-center">
                    <img src="@/assets/images/learning/lesson_plan.png" alt="">
                    <h4 class="font-weight-bolder">Lesson plans</h4>
                    <p>We see you haven’t created any lesson plans yet, let’s get started.</p>
                    <b-button 
                      v-if="hasPermission('Create Lesson Plan Materials')"
                      class="btn rounded-pill ml-auto" 
                      variant="primary"
                      @click="createLesson()"
                    >
                      Create lesson plan
                    </b-button>
                  </div>
                </b-row>
            </b-tab> -->
            <b-tab title="Recommended learning">
              <RecommendedLearning />
            </b-tab>
          </b-tabs>
          <!-- <b-button 
            class="btn rounded-pill ml-auto py-0" 
            variant="primary"
            style="position: absolute ; right: 10px; top: 12px; height: 40px"
            @click="createLesson()"
          >
            Create lesson plan
          </b-button> -->
      </b-row>
  </div>
</template>

<script>
import {
  BCard, 
  BRow, 
  BCol, 
  BCardText, 
  BButton,
  BLink, 
  BListGroup, 
  BListGroupItem, 
  BCardTitle, 
  BCardBody,
  BTab, BTabs,
  BAlert,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from "vuex";
import apexChatData from '@/views/charts-and-maps/charts/apex-chart/apexChartData.js'
import CardAdvanceEmployeeTask from '@/views/card/card-advance/CardAdvanceEmployeeTask.vue'
import RecommendedLearning from '@/views/modules/learning/RecommendedLearning.vue'

export default {
  components: {
    BCard, 
    BRow, 
    BCol, 
    BCardText, 
    BButton,
    BLink, 
    BListGroup, 
    BListGroupItem, 
    BCardTitle, 
    BCardBody,
    BTab, BTabs,
    BAlert,
    VueApexCharts,
    CardAdvanceEmployeeTask,
    RecommendedLearning,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters({
      progress: "teachers/progress",
      loadProg: "teachers/loadProg",
    }),
    
  },
  data() {
    return {
      error:false,
      error_msg:null,
      apexChatData,
      items: [
        {title: 'School 1', students: '50 Student'},
        {title: 'School 2', students: '80 Student'},
        {title: 'School 3', students: '30 Student'},
        {title: 'School 4', students: '20 Student'},
        {title: 'School 5', students: '35 Student'},
      ]
    }
  },
  methods:{
    createLesson(){
      this.$router.push({name: 'createLessonPlan'})
    },
    init(){
      this.$store.dispatch('teachers/studentsProgress').then((resp)=>{
      }).catch((error)=>{
        this.error = true;
        this.error_msg = error.response.data.error;
      });
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/dashboard-ecommerce.scss';
  @import '@core/scss/vue/libs/chart-apex.scss';
  .nav-tabs .nav-link:after {
    background: linear-gradient(90deg, rgba(232,65,133,1) 0%, rgba(232,65,133,0.6587009803921569) 100%) !important;
  }
  .nav-tabs .nav-link.active {
    color: #E84185;
  }
  .btn-primary:focus {
    background-color: #E84185 !important;
  }
  .btn-primary {
    border-color: #E84185 !important;
    background-color: #E84185 !important;
  }
  .alert-danger{
    background-color: #CF4F4F !important;
    color: #fff !important;
    border-radius: 10px !important;
  }
  [dir] .nav-tabs {
    margin-bottom: 1rem;
    background-color: #fff;
    box-shadow: 5px 10px #8888883d;
    box-shadow: 10px #ccc;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    padding-top: 30px;
  }
  .dark-tabs .nav-tabs {
    margin-bottom: 1rem;
    background-color: #0b0b0b;
    box-shadow: 5px 10px #8888883d;
    box-shadow: unset !important;
    padding-top: 30px;
  }
</style>
