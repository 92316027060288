<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="items.count_unread"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <div class="d-flex">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="py-50 px-1"
            style="font-size: .8rem;"
            block
            @click="markAllAsRead()"
          >
            Mark all as read
          </b-button>
        </div>
      </div>
    </li>

    <!-- Notifications -->
    <center v-if="load">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </center>
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
      v-else-if="items && items.notifications && items.notifications && items.notifications.data.length > 0"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in items.notifications.data"
        :key="notification.id"
      >
        <b-media :class="notification.read_at ? '' : 'unread-msg'">
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.avatar"
              :text="notification.avatar"
              :variant="'primary'"
            />
          </template>
          <div class="notification-body" @click.stop="markAsRead(notification.id,notification.type)">
            <p class="media-heading my-auto">
              <span class="font-weight-bolder">
                {{ notification.data }}
              </span>
            </p>
            <small class="notification-text">{{ notification.msg + ' ' + notification.sent_from }} </small>
            <div
              v-if="!notification.read_at"
              class="notification-active rounded-circle p-25 "
            >
            </div>
          </div>
        </b-media>
      </b-link>

      <!-- System Notification Toggler -->
      <!-- <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          System Notifications
        </h6>
        <b-form-checkbox
          :checked="true"
          switch
        />
      </div> -->

      <!-- System Notifications -->
      <!-- <b-link
        v-for="notification in items.notifications"
        :key="notification.id"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="'primary'"
            >
              <feather-icon :icon="'AlertTriangleIcon'" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.data }}
            </span>
          </p>
          <small class="notification-text">{{ notification.msg }}</small>
        </b-media>
      </b-link> -->
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer" v-if="items && items.notifications">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="loadMoreNotifications()"
        :disabled="items.notifications.total == items.notifications.to ? true : false"
        :class="items.notifications.total == items.notifications.to ? 'cursor-disabled' : ''"
      >Read More notifications</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
    BSpinner,
    BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getMessaging,onMessage} from "firebase/messaging";
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    BSpinner,
    ToastificationContent
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters({
        items: "notifications/items",
        load: "notifications/load",
    }),
    id() {
        return this.$route.query.course_id
            ? this.$route.query.course_id 
            : null;
    },
  },
  data(){
    return{
      form:{
        per_page: 10
      }
    }
  },
  setup() {
    /* eslint-disable global-require */
    const notifications = [
      {
        title: 'Congratulation Sam 🎉',
        avatar: require('@/assets/images/avatars/6-small.png'),
        subtitle: 'Won the monthly best seller badge',
        type: 'light-success',
      },
      {
        title: 'New message received',
        avatar: require('@/assets/images/avatars/9-small.png'),
        subtitle: 'You have 10 unread messages',
        type: 'light-info',
      },
      {
        title: 'Revised Order 👋',
        avatar: 'MD',
        subtitle: 'MD Inc. order updated',
        type: 'light-danger',
      },
    ]
    /* eslint-disable global-require */

    const systemNotifications = [
      {
        title: 'Server down',
        subtitle: 'USA Server is down due to hight CPU usage',
        type: 'light-danger',
        icon: 'XIcon',
      },
      {
        title: 'Sales report generated',
        subtitle: 'Last month sales report generated',
        type: 'light-success',
        icon: 'CheckIcon',
      },
      {
        title: 'High memory usage',
        subtitle: 'BLR Server using high memory',
        type: 'light-warning',
        icon: 'AlertTriangleIcon',
      },
    ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      notifications,
      systemNotifications,
      perfectScrollbarSettings,
    }
  },
  methods:{
    loadMoreNotifications(){
      this.form.per_page += 10
      const payload = this.form
      this.$store.dispatch('notifications/list', payload)
    },
    markAllAsRead(){
      this.$store.dispatch('notifications/markAllAsRead').then(_=>{
        const payload = this.form
        this.$store.dispatch('notifications/list', payload)
      })
    },
    markAsRead(id,type){
      
      if (type == 'enroll_request' ) {
        if (this.$route.name != 'requests') {
          this.$store.dispatch('notifications/markAsRead', id).then(_=>{
            const payload = this.form
            this.$store.dispatch('notifications/list', payload)
          })
          this.$router.push({ name: 'requests' })
        }
      }else{
        this.$store.dispatch('notifications/markAsRead', id).then(_=>{
          const payload = this.form
          this.$store.dispatch('notifications/list', payload)
        })
      }
    }
  },  
  mounted(){
    const messaging = getMessaging();

    // Listen for foreground messages
    onMessage(messaging, (payload) => {
      this.$store.dispatch('notifications/list', payload).then(_=>{
            let src = require('@/assets/audio/system-notification.mp3');
            let audio = new Audio(src);
            audio.play();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.items.notifications.data[0].data,
                // description: this.items.notifications[0].msg,
                icon: 'BellIcon',
              },
            })
          })
    });
    const payload = this.form
    this.$store.dispatch('notifications/list', payload)
    // import('@/views/echo.js').then(() => {
    //   window.Echo.channel(`channel_user_${this.authUser().user.id}`)
    //     .listen('NewNotification', (event) => {
    //       this.$store.dispatch('notifications/list', payload).then(_=>{
    //         let src = require('@/assets/audio/system-notification.mp3');
    //         let audio = new Audio(src);
    //         audio.play();
    //         this.$toast({
    //           component: ToastificationContent,
    //           props: {
    //             title: this.items.notifications.data[0].data,
    //             // description: this.items.notifications[0].msg,
    //             icon: 'BellIcon',
    //           },
    //         })
    //       })
    //     });
    // });

  }
}
</script>

<style scoped>

.notification-body{
  position: relative;
}
.notification-active{
  background-color: #E84185;
  position: absolute;
  top: 48%;
  right: 0;
}

</style>
