<template>
    <div>

        <center  v-if="loadCertificate">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
        </center>
        <center v-if="certificate == null">
            <strong> No Certificate Yet</strong>
        </center>
        <b-row v-else>
            
        <b-col cols="8">
            <div  id="certificateDiv" class="pt-1 w-100 dark-topic-container px-1 pr-4 shadow">
                <b-row>
                    <b-col cols="2">
                    <img src="@/assets/images/certificate/logo.jpeg" alt="" class="logo" />
                    </b-col>
                    <b-col cols="10" class=" text-right" >
                    <img :src="certificate.module_certificate_logo" alt="certificate_logo" class="certificate_logo pt-1" /> 
                    <h3 class="m-0 pt-3" style="text-transform: uppercase;"> <strong>{{certificate.module_name}}!</strong></h3>
                    <span>
                        <p class="pt-1 ">This certificate of completion is awarded to</p>
                    </span>
                    <span>
                        <div style="width: fit-content; margin-left: auto;">
                        <p class="pt-1 pl-1 pr-1" style="font-family: monospace !important; font-size: larger;">
                            {{certificate.user_fname}} {{certificate.user_lname}}, {{certificate.school_name}}
                        </p>
                        <hr >
                        </div>
                        Completed a {{certificate.module_desc}}
                    </span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="4">
                    <img src="@/assets/images/certificate/triangles.jpeg" alt="" class="logo" />
                    </b-col>
                    <b-col cols="4">
                    <div class="p-2 pt-3 text-right">
                        <hr >
                        <p class="m-0 signature-title" ><strong>{{certificate.module_author_name}}</strong></p>
                        <p > {{certificate.module_author_title}}</p>
                        
                    </div>
                    </b-col>
                    <b-col cols="4">
                        <div class=" pt-3 text-right">
                            <hr >
                            <p class="m-0 signature-title" ><strong>{{certificate.HL_author}}</strong></p>
                            <p >  {{certificate.HL_author_title}}</p>
                            
                        </div>
                        </b-col>
                    
                </b-row>
            </div>
        </b-col>
        <b-col cols="4" class="pt-1">
            share via:
            <div class="d-flex justify-content-left pt-1" @click="shareMedia('linkedin')">
            <p class="default-icon">
                <feather-icon
                icon="LinkedinIcon"
                size="16"
                style="color: black;"
                />  
            </p>
            <p class="circle-icon ml-2" @click="shareMedia('facebook')">
                <feather-icon
                icon="FacebookIcon"
                size="16"
                style="color: black;"
                />  
            </p>
            <p class="circle-icon ml-2" @click="shareMedia('Mail')">
                <feather-icon
                icon="MailIcon"
                size="16"
                style="color: black;"
                
                />  
            </p>
            </div>
            <div class="pt-3 pl-1">
                <b-button 
                    class="btn rounded-pill btn-sm pl-4 pr-4"
                    variant="primary"
                    @click="downloadCertificate" 
                    :disabled="download"
                >
                    <feather-icon
                        icon="DownloadIcon"
                        size="16"
                        
                    />  
                    Download
                </b-button>
            </div>
            <!-- <div class="pt-3 pl-1">
            <b-button 
                class="btn rounded-pill btn-sm pl-2 pr-2"
                variant="primary"
            >
            <feather-icon
                icon="GitMergeIcon"
                size="18"
            />  continue to quests
            </b-button>
            </div> -->
            
        </b-col>
        </b-row>
    
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import html2canvas from 'html2canvas';
import {
  BCard, 
  BRow, 
  BCol, 
  BCardText, 
  BButton,
  BLink, 
  BListGroup, 
  BListGroupItem, 
  BCardTitle, 
  BCardBody,
  BTab, BTabs,
  BSpinner,
  BBadge,
  BCardImg,
  BProgress,
  BProgressBar,
  VBTooltip,
  BAlert
} from 'bootstrap-vue'
export default {
    components: {
        BCard, 
        BRow, 
        BCol, 
        BCardText, 
        BButton,
        BLink, 
        BListGroup, 
        BListGroupItem, 
        BCardTitle, 
        BCardBody,
        BTab, BTabs,
        BSpinner,
        BAlert,
        BBadge,
        BProgressBar,
        BProgress,
        BCardImg,
    },
    computed: {
        ...mapGetters({
            certificate: "students/certificate",
            cryptCert: "students/cryptCert",
            loadCertificate: "students/loadCertificate",
            
        }),
        student_id(){ 
            return this.$route.params.id ? this.$route.params.id : null
        },
    },
    data(){
        return {
            download:false
        }
    },
    methods:{
        shareMedia(platform) {
            let baseLink = 'https://hustlelab.vero-cloud.com/shareCertificate/'+this.cryptCert.st+'/'+this.cryptCert.mod;
            let message = "I'm thrilled to announce that I have completed the course!";
            let shareText = `${baseLink} ${encodeURIComponent(message)}`;
            
            if (platform === 'linkedin') {
                console.log('------------linkedin---');
                
                let linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(baseLink)}&summary=${encodeURIComponent(message)}`;
                window.open(linkedinUrl, '_blank');
            }

            if (platform === 'facebook') {
                let facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareText)}`;
                window.open(facebookUrl, '_blank');
            }
        },
        downloadCertificate() {
            this.download = true;
            const certificateDiv = document.getElementById('certificateDiv');
            html2canvas(certificateDiv).then(canvas => {
                const link = document.createElement('a');
                link.href = canvas.toDataURL('image/png');
                link.download = this.certificate.module_name+'_'+this.certificate.user_fname+' certificate.png';
                link.click();
            });
            this.download = false;
        }

    }
}
</script>
<style lang="css">
.dark-topic-container{
    border: 1px solid #3A3A3A;
    border-radius: 20px;
    background-color: #000000;
}
.circle-icon {
    cursor:pointer;
    color: #000000;
    background-color: #f8f9fa;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%; /* Makes it a circle */
    border: 2px solid #1b1b1c; /* Adds the border */
  }
  .default-icon {
    cursor:pointer;
    color: #000000;
    background-color: #f8f9fa;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:5px; /* Makes it a circle */
    border: 2px solid #1b1b1c; /* Adds the border */
  }
  .signature-title{
    text-transform: uppercase;
    color: #e4229a;
  }
  .certificate_logo{
    width: 96px;
    height: 45px;
  }
</style>