<template>
  <div>
    <!-- <div class="d-flex justify-content-end" >
        <div class="d-flex justify-content-center ">
            <p class="mt-3">Your progress</p>
            <vue-apex-charts
                type="radialBar"
                height="120"
                width="120"
                :options="goalOverviewRadialBarProgress.chartOptions"
                :series="goalOverviewRadialBarProgress.series"
            />
        </div>
    </div>-->
    <b-row v-if="item.type == 1">
      <b-col md="2"></b-col>
      <b-col md="8">
        <b-card :class="$store.state.appConfig.layout.skin == 'dark' ? 'bg-custom-card-dark' : ''">
          <div class="video-container" ref="videoContainer" v-if="renderVideo" @mouseover="showControls = true" @mouseleave="showControls = false">
            <video
              v-if="renderVideo"
              ref="videoPlayer"
              width="100%"
              height="100%"
              @timeupdate="handleTimeUpdate"
              @loadedmetadata="initializeSeekbar"
            >
              <source :src="videoPath" type="video/mp4" />
            </video>
            <transition name="fade">
              <div class="controls" v-if="showControls">
                <button
                  @click="playPause"
                  :disabled="showQuizOverlay"
                  class="btn play-pause-btn mx-50"
                >
                  <feather-icon
                    icon="PauseIcon"
                    size="23"
                    style="color: aliceblue;"
                    v-if="isPlaying"
                  />
                  <feather-icon icon="PlayIcon" size="23" style="color: aliceblue;" v-else />
                </button>
                <div class="video-seek cursor-pointer">
                  <input
                    type="range"
                    min="0"
                    :max="videoDuration"
                    v-model="seekbarValue"
                    @input="onSeekbarInput"
                    @mousedown="startSeek"
                    @mouseup="endSeek"
                    class="custom-seekbar cursor-pointer"
                  />
                  <div
                    v-for="(quiz, index) in quizData"
                    :key="index"
                    :style="pointPosition(index)"
                    class="marker"
                  >
                    <feather-icon
                      icon="SendIcon"
                      size="14"
                      style="color: aliceblue;"
                      v-if="!answeredQuestions.includes(quiz.id)"
                    />
                    <feather-icon icon="CheckIcon" size="18" style="color: aliceblue;" v-else />
                  </div>
                </div>
                <div class="timer-display mr-25">{{ displayTime }}</div>
                <button @click="toggleMute" class="mute-btn mx-25">
                  <!-- <font-awesome-icon :icon="isMuted ? ['fas', 'volume-mute'] : ['fas', 'volume-up']" /> -->
                  <feather-icon icon="VolumeXIcon" size="23" v-if="isMuted" />
                  <feather-icon icon="Volume2Icon" size="23" v-else />
                </button>
                <button @click="toggleFullscreen" class="fullscreen-btn mr-50">
                  <!-- <font-awesome-icon icon="expand" /> -->
                  <feather-icon icon="MaximizeIcon" size="23" />
                </button>
              </div>
            </transition>
            <div v-if="showQuizOverlay" class="quiz-overlay">
              <h4 class="mb-2">Time to test what you have learnt</h4>
              <p>{{ currentQuestion.question }}</p>
              <form @submit.prevent="submitAnswer">
                <div class="quiz-options">
                  <div
                    v-for="(option, index) in currentQuestion.shuffle_options"
                    :key="index"
                    class="quiz-option"
                  >
                    <!-- <label :class="{ selected: selectedOption === option.id }" @click="selectOption(option.id)"> -->
                    <label
                      :class="getLabelOptionClass(option.id)"
                      @click="selectOption(option.id)"
                    >{{ option.value }}</label>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <button
                    v-if="!isSubmitted"
                    class="btn btn-primary rounded-pill px-3"
                    type="submit"
                  >Submit</button>
                  <button
                    v-else
                    @click="closeOverlay"
                    class="btn rounded-pill px-3 text-light"
                    type="button"
                  >Done</button>
                </div>
              </form>
            </div>
          </div>
          <center v-else>
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </center>
          <b-row>
            <b-col md="12" class="d-flex justify-content-center" v-if="!loadItem">
              <!-- <button class="btn btn-primary rounded-pill mt-3 px-4" @click="$router.push({name: 'finish_lesson'})">Finish</button> -->
              <!-- <button class="btn btn-primary rounded-pill mt-3 px-4" v-if="quizData.length == answeredQuestions.length" @click="finish">Finish</button> -->
              <button
                class="btn btn-primary rounded-pill mt-3 px-4"
                v-if="seekbarValue >= videoDuration && item != null && videoDuration > 0"
                @click="finish"  :disabled="loadFinish"
              >
              <b-spinner v-if="loadFinish" variant="light" style="width: 20px; height: 20px;" label="Spinning"></b-spinner>
              <div v-else>Finish</div> 
              </button>
              
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="2"></b-col>
    </b-row>
    <b-row v-if="item.type == 2">
      <b-col md="12">
        <Assessment :data="item" />
      </b-col>
    </b-row>
    <b-row v-if="item.type == 3">
      <b-col md="12">
        <OpenQuestions :data="item" />
      </b-col>
    </b-row>
    <b-row v-if="item.type == 4">
      <b-col md="12">
        <ImageTopic :data="item" />
      </b-col>
    </b-row>
    <b-row v-if="item.type == 5">
      <b-col md="12">
        <TextTopic :data="item" />
      </b-col>
    </b-row>
    <b-row v-if="item.type == 6">
      <b-col md="12">
        <PDFTopic :data="item" />
      </b-col>
    </b-row>
    <b-row v-if="item.type == 7">
      <b-col md="12">
        <ThingLinkTopic :data="item" />
      </b-col>
    </b-row>
    <b-row v-if="item.type == 8">
      <b-col md="12">
        <YoutubeTopic :data="item" />
      </b-col>
    </b-row>
  </div>
</template>





<script>
import axios from "axios";
import { mapGetters } from "vuex";
import serverURL from "@/constants";
import VueApexCharts from "vue-apexcharts";
import Assessment from "./Assessment.vue";
import OpenQuestions from "./OpenQuestions.vue";
import ImageTopic from "./ImageTopic.vue";
import PDFTopic from "./PDFTopic.vue";
import TextTopic from "./TextTopic.vue";
import ThingLinkTopic from "./YoutubeTopic.vue";
import YoutubeTopic from "./YoutubeTopic.vue";

import {
  BIcon,
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BSpinner,
  BCardTitle,
  BCardBody,
  BTab,
  BTabs,
  BAlert
} from "bootstrap-vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: {
    VueApexCharts,
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BSpinner,
    BCardBody,
    BTab,
    BTabs,
    BAlert,
    FeatherIcon,
    BIcon,
    Assessment,
    OpenQuestions,
    ImageTopic,
    PDFTopic,
    TextTopic,
    ThingLinkTopic,
    YoutubeTopic
  },
  data() {
    return {
      videoPath: "",
      videoName: null,
      showControls: true,
      quizData: [],
      currentQuizIndex: 0,
      showQuizOverlay: false,
      currentQuestion: {},
      selectedOption: null,
      userAnswer: null,
      correctAnswer: null,
      renderVideo: false,
      type: null,
      deployLoad:false,
      currentTime: null,
      module_id: null,
      displayTime: "0:00:00",
      isSubmitted: false,
      answeredQuestions: [], // List of answered question IDs
      videoDuration: 0,
      seekbarValue: 0,
      isPlaying: false, // Track play/pause state
      bufferTime: 10, // Buffer time to adjust video play position
      isMuted: false,
      seeking: false,
      lastDispatchedTime:0,
      goalOverviewRadialBarProgress: {
        series: [this.$route.params.progress],
        chartOptions: {
          chart: {
            // height: 90,
            type: "radialBar",
            toolbar: {
              show: false
            }
          },
          plotOptions: {
            radialBar: {
              startAngle: -135,
              endAngle: 225,
              hollow: {
                margin: 0,
                size: "65%",
                background: "#fff",
                image: undefined,
                imageOffsetX: 0,
                imageOffsetY: 0,
                position: "front",
                dropShadow: {
                  enabled: true,
                  top: 0,
                  left: 0,
                  blur: 5,
                  opacity: 0.24
                }
              },
              track: {
                background: "#fff",
                strokeWidth: "60%",
                margin: 0, // margin is in pixels
                dropShadow: {
                  enabled: true,
                  bottom: -3,
                  left: 0,
                  blur: 4,
                  opacity: 0.55
                }
              },

              dataLabels: {
                show: true,
                name: {
                  offsetY: -10,
                  show: false,
                  color: "#888",
                  fontSize: "22px"
                },
                value: {
                  formatter: function(val) {
                    return parseInt(val) + "%";
                  },
                  color: "#111",
                  fontSize: "20px",
                  show: true
                }
              }
            }
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              type: "horizontal",
              shadeIntensity: 1,
              gradientToColors: ["#E84185"],
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100]
            }
          },
          stroke: {
            lineCap: "round"
          },
          labels: ["Percent"]
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      item: "topics/item",
      loadItem: "topics/loadItem",
      loadFinish: "lessonPlans/loadFinish"
    }),
    id() {
      return this.$route.params.id ? this.$route.params.id : null;
    }
  },
  mounted() {
    this.init();
    // this.timeoutLaod()
    // this.loadUserAnswers();
  },
  methods: {
    
    selectOption(id) {
      this.selectedOption = id;
    },
    checkPosition(index) {
      let position = (this.quizData[index].q_in_sec / this.videoDuration) * 100;
      return position;
    },
    pointPosition(index) {
      let position = (this.quizData[index].q_in_sec / this.videoDuration) * 100;
      // position -= 1;
      return { left: `${position - 0.5}%` };
    },
    toggleFullscreen() {
      const videoContainer = this.$refs.videoContainer;

      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        // Enter fullscreen
        if (videoContainer.requestFullscreen) {
          videoContainer.requestFullscreen();
        } else if (videoContainer.mozRequestFullScreen) {
          videoContainer.mozRequestFullScreen();
        } else if (videoContainer.webkitRequestFullscreen) {
          videoContainer.webkitRequestFullscreen();
        } else if (videoContainer.msRequestFullscreen) {
          videoContainer.msRequestFullscreen();
        }
      } else {
        // Exit fullscreen
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    },

    init() {
      if (this.$route.params.id) {
        this.$store
          .dispatch("topics/get", this.$route.params.id)
          .then(response => {
            this.renderVideo = true;
            this.type = response.topic[0].type;
            this.videoName = response.topic[0].video_name;
            this.videoPath = response.topic[0].video_path;
            this.quizData = response.topic[0].subTopics[0].MCQ;
            this.module_id = response.topic[0].module_id;
          })
          .finally(_ => {
            this.loadVideoData();
          });
      }
    },
    async loadVideoData() {
      try {
        this.videoPath = `${serverURL}/stream/videos/${this.videoName}`;

        // Check if the response has a subtitle path
        // if (response.data.subtitles_path) {
        //   this.subtitlesPath = serverURL + "/" + response.data.subtitles_path;
        // }

        // this.renderVideo = true;
        // this.quizData = response.data.quiz_questions;
        this.$nextTick(() => {
          const videoPlayer = this.$refs.videoPlayer;
          this.currentTime = videoPlayer.currentTime;
          if (videoPlayer) {
          } else {
          }
        });
      } catch (error) {}
    },
    async loadUserAnswers() {
      try {
        await axios
          .get(serverURL + "/api/user-answers?user_id=1")
          .then(response => {
            this.answeredQuestions = response.data.map(
              answer => answer.quiz_question_id
            );
          });
      } catch (error) {
        console.error("Error loading user answers:", error);
      }
    },
    checkQuiz() {
      const videoPlayer = this.$refs.videoPlayer;

      if (this.currentQuizIndex >= this.quizData.length) return;

      const currentQuestion = this.quizData[this.currentQuizIndex];

      // Check if the quiz question is due for display
      // if (videoPlayer.currentTime >= currentQuestion.seconds && !this.answeredQuestions.includes(currentQuestion.id)) {
      if (
        videoPlayer.currentTime >= currentQuestion.q_in_sec &&
        !this.answeredQuestions.includes(currentQuestion.id)
      ) {
        videoPlayer.pause();
        this.isPlaying = false;
        this.showQuizOverlay = true;
        this.currentQuestion = currentQuestion;
        this.correctAnswer = currentQuestion.correct_answer;
        // this.correctAnswer = 1;
        this.userAnswer = null;
        this.isSubmitted = false;
      }
    },
    finish() {
      const payload = {
        topic_id: this.$route.params.id
      };
      this.$store.dispatch("topics/watched", {topic_id: this.$route.params.id,minute: (this.seekbarValue / 60).toFixed(2),});
      this.$store.dispatch("lessonPlans/finishTopic", payload).then(resp => {
        this.$router.push({
          name: "finish_lesson",
          params: {
            result: resp.result,
            module_id: this.module_id,
            current_id: this.$route.params.id,
            next_id: resp.next_id
          }
        });
      });
    },
    async submitAnswer() {
      const question = this.currentQuestion;
      const answer = this.selectedOption;
      this.userAnswer = answer;

      try {
        const payload = {
          question_id: this.currentQuestion.id,
          selected_option: this.selectedOption,
          answer: this.userAnswer,
          type: this.type,
          topic_id: this.$route.params.id
        };
        this.$store.dispatch("lessonPlans/answerQue", payload).then(resp => {
          this.correctAnswer = 1;
          this.isSubmitted = true;
          this.answeredQuestions.push(question.id);
        });
        // await axios.post(`${serverURL}/api/user-answers`, {
        //   user_id: 1, // Example user ID
        //   quiz_question_id: question.id,
        //   selected_option: answer,
        //   correct: answer === question.correct_answer,
        // });
        // this.isSubmitted = true;
        // this.answeredQuestions.push(question.id); // Mark the question as answered
      } catch (error) {
        console.error("Error saving answer:", error);
      }
    },
    closeOverlay() {
      this.showQuizOverlay = false;
      this.currentQuizIndex++;
      this.selectedOption = null;
      this.$refs.videoPlayer.play();
      this.isPlaying = true;
    },
    getLabelOptionClass(id) {
      if (this.userAnswer === null) {
        // return { "selected-option": this.selectedOption === index };
      }
      if (id === this.correctAnswer) {
        return "correct";
      }
      if (id === this.selectedOption) {
        // return "incorrect-option";
        return "selected";
      }
      return "";
    },
    getOptionClass(id) {
      if (this.userAnswer === null) {
        // return { "selected-option": this.selectedOption === index };
      }
      if (id === this.correctAnswer) {
        return "correct-option";
      }
      if (id === this.userAnswer) {
        // return "incorrect-option";
        return "selected";
      }
      return "";
    },

    onSeekbarInput(event) {
      const videoPlayer = this.$refs.videoPlayer;
      const newTime = parseFloat(event.target.value);
      videoPlayer.currentTime = newTime;
      this.seekbarValue = newTime;
      this.checkQuiz();
      // console.log('------------------check click');
      
    },

    startSeek() {
      this.seeking = true;
    },

    endSeek() {
      this.seeking = false;
      this.checkQuiz(); // Check quiz conditions after seeking
    },

    formatTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
        remainingSeconds < 10 ? "0" : ""
      }${remainingSeconds}`;
    },

    initializeSeekbar() {
      const videoPlayer = this.$refs.videoPlayer;

      // Initialize seekbar value and video duration
      this.videoDuration = videoPlayer.duration;

      this.displayTime = this.formatTime(this.videoDuration);
      // this.videoDuration = Math.floor(videoPlayer.duration);

      this.seekbarValue = videoPlayer.currentTime;
      this.currentTime = videoPlayer.currentTime;
      
      
      // Listen for time updates
      videoPlayer.addEventListener("timeupdate", () => {
        // if (!this.seeking) {
        //   this.seekbarValue = videoPlayer.currentTime;
        //   // this.seekbarValue = (videoPlayer.currentTime/this.videoDuration)*100;
        // }
        this.handleTimeUpdate();
        this.checkQuiz();
      });
    },

    playPause() {
      const videoPlayer = this.$refs.videoPlayer;
      if (this.isPlaying) {
        videoPlayer.pause();
      } else {
        videoPlayer.play();
      }
      this.isPlaying = !this.isPlaying;
    },
    toggleMute() {
      const videoPlayer = this.$refs.videoPlayer;
      videoPlayer.muted = !videoPlayer.muted;
      this.isMuted = videoPlayer.muted;
    },
    updateSeekbar() {
      const videoPlayer = this.$refs.videoPlayer;
      
      if (!this.seeking) {
        this.seekbarValue = videoPlayer.currentTime;
        // this.seekbarValue = (videoPlayer.currentTime/this.videoDuration)*100;
      }
      if (Math.floor(this.seekbarValue / 60) > this.lastDispatchedTime) {
        this.lastDispatchedTime = Math.floor(this.seekbarValue / 60); 
        this.$store.dispatch("topics/watched", {topic_id: this.$route.params.id,minute: Math.floor(this.seekbarValue / 60).toFixed(2),});
      }
    },

    handleTimeUpdate() {
      const videoPlayer = this.$refs.videoPlayer;
      this.seekbarValue = videoPlayer.currentTime;

      // Calculate remaining time
      const remainingTime = this.videoDuration - videoPlayer.currentTime;

      // Update the display with the remaining time
      this.displayTime = this.formatTime(remainingTime);

      this.updateSeekbar();
      this.checkQuiz();
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1e1e1e;
  color: white;
  padding: 20px;
  border-radius: 10px;
}

video {
  border-radius: 10px;
}

.controls {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  box-sizing: border-box; */
}

.video-container {
  position: relative;
  width: 100%;
  height: auto;
  background-color: #1e1e1e;
  border-radius: 10px;
  overflow: hidden; /* Ensure the overlay stays within bounds */
}

.video-seek {
  position: relative;
  width: 100%;
}

.btn {
  background-color: #e84180;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
}

.mute-btn,
.fullscreen-btn {
  background-color: #1e1e1e;

  color: white;
  border: none;
  padding: 8px; /* Adjusted padding */
  cursor: pointer;
  border-radius: 15px;
}
.play-pause-btni {
  font-size: 20px; /* Larger icon size */
}

.mute-btn i {
  font-size: 20px; /* Larger icon size */
}

.fullscreen-btn i {
  font-size: 20px; /* Larger icon size */
}

.custom-seekbar {
  -webkit-appearance: none;
  width: 100%; /* Adjust width to fit controls */
  height: 8px;
  background: #e84180;
  border-radius: 5px;
  outline: none;
  margin: 0;
}

.custom-seekbar::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
}

.custom-seekbar::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: white;
  border-radius: 50%;
  cursor: pointer;
}

.quiz-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.792);
  color: white;
  padding: 20px;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 90%;
  /* max-width: 500px; */
  height: 80%;
  text-align: start;
  border-radius: 15px;
}

.quiz-overlay form button {
  position: absolute;
  bottom: 15px;
}

.quiz-options {
  display: flex;
  flex-wrap: wrap;
}
.quiz-option {
  margin: 0;
  padding: 8px;
}

.quiz-option label {
  display: flex;
  justify-content: left;
  align-items: center;
  justify-content: start;
  background-color: rgba(0, 0, 0, 0.61);
  color: white;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 300px;
  margin-right: 20px;
  border: 2px solid white;
  transition: border-color 0.3s;
}
/* .selected {
  border-color: #ff3366;
} */
.quiz-option label.selected {
  border-color: #e84180;
}

.quiz-option label.correct {
  border-color: #28a745;
}
.quiz-option input[type="checkbox"] {
  display: none;
}

.selected-option {
  background-color: #e84180;
}

.correct-option {
  background-color: #28a745;
}

.incorrect-option {
  background-color: #dc3545;
}

.submit-btn,
.done-btn {
  background-color: #e84180;
}

.submit-btn:hover,
.done-btn:hover {
  background-color: #e02f5f;
}

.marker {
  text-align: center;
  position: absolute;
  top: -2px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #e84180;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timer-display {
  color: white;
  font-size: 12px;
  margin-left: 10px;
  display: flex;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>