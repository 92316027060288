<template>
  <div>
    <b-card
      :class="$store.state.appConfig.layout.skin === 'dark' ? 'dark-card rounded-lg' : 'rounded-lg'"
    >
      <b-row>
        
        <b-col md="2"></b-col>
        <b-col md="8">
          <b-toast
            primary
            no-auto-hide
            noCloseButton
            v-model="showToast"
            append-toast
            b-toaster-bottom-center
            id="example-toast"
          >
            <template #toast-title>
              <div class="d-flex flex-grow-1 align-items-center mr-1 pt-2">
                <strong class="mr-auto">Uploading ...</strong>
              </div>
            </template>
            <div v-if="uploadProgress" class="progress-wrapper">
              <b-card-text class="mb-0">{{ uploadProgress ? uploadProgress + "%" : "" || "" }}</b-card-text>
              <b-progress v-model="uploadProgress" max="100" />
            </div>
          </b-toast>
          <!-- {{ loadItem }} -->
          <center v-if="loadItem">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </center>
          <b-form v-else class="chat-app-form" @submit.prevent="submit">
            <validation-observer class="w-100" ref="certificateForm" #default="{invalid}">
              <b-row class="pt-3">
                <b-col md="6" class="mb-2">
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    vid="name"
                    rules="required"
                  >
                    <b-form-group label="Certificate Name *" label-for="name">
                      <b-input-group class="input-group-merge form-send-message mr-1">
                        <b-form-input
                          autocomplete="off"
                          v-model="form.name"
                          placeholder="Enter Certificate Name"
                          id="name"
                          class="custom-field"
                        />
                      </b-input-group>
                      <small class="text-danger float-left">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6" class="mb-2">
                  <validation-provider
                    #default="{ errors }"
                    name="description"
                    vid="description"
                    rules="required"
                  >
                    <b-form-group label="Certificate Description *" label-for="description">
                      <b-input-group class="input-group-merge form-send-message mr-1">
                        <b-form-input
                          autocomplete="off"
                          v-model="form.description"
                          placeholder="Enter Certificate Description"
                          id="description"
                          class="custom-field"
                        />
                      </b-input-group>
                      <small class="text-danger float-left">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6" class="mb-2" v-if="!loadCourses">
                  
                  <validation-provider
                    #default="{ errors }"
                    name="course_id"
                    vid="course_id"
                    rules="required"
                  >
                  
                    <b-form-group label="Certificate Courses *" label-for="course_id">
                      <v-select
                        v-model="form.course_id"
                        :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                        :options="courses"
                        id="course_id"
                        class="w-100 mb-0 custom-field"
                        :reduce="(val) => val.id"
                        
                        placeholder="Select Course"
                        :label="$i18n.locale == 'en' ? 'name' : 'name_local'"
                      >
                        <template
                          v-slot:option="option"
                        >{{ $i18n.locale == "ar" ? option.name_local : option.name }}</template>
                        <template #selected-option="{ name, name_local }">
                          <div style="display: flex; align-items: baseline">
                            <strong v-if="$i18n.locale == 'ar'">{{ name_local }}</strong>
                            <strong v-else>{{ name }}</strong>
                          </div>
                        </template>
                        <template #no-options>{{ $t("noMatching") }}</template>
                      </v-select>
                      <small class="text-danger float-left">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6" class="mb-2">
                  
                  <validation-provider
                    #default="{ errors }"
                    name="modules_id"
                    vid="modules_id"
                    rules="required"
                  >
                    <b-form-group label="Certificate modules *" label-for="modules_id">
                      <v-select
                        v-model="form.modules_id"
                        :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                        :options="modules"
                        id="modules_id"
                        class="w-100 mb-0 custom-field"
                        :reduce="(val) => val.id"
                        multiple
                        placeholder="Select modules"
                        :label="$i18n.locale == 'en' ? 'name' : 'name_local'"
                        :disabled="loadModules"
                      >
                        <template
                          v-slot:option="option"
                        >{{ $i18n.locale == "ar" ? option.name_local : option.name }}</template>
                        <template #selected-option="{ name, name_local }">
                          <div style="display: flex; align-items: baseline">
                            <strong v-if="$i18n.locale == 'ar'">{{ name_local }}</strong>
                            <strong v-else>{{ name }}</strong>
                          </div>
                        </template>
                        <template #no-options>{{ $t("noMatching") }}</template>
                      </v-select>
                      <small class="text-danger float-left">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6" class="mb-2">
                          <validation-provider
                            #default="{ errors }"
                            name="author_name"
                            vid="author_name"
                            rules="required"
                          >
                            <b-form-group label="Author Name*" label-for="author_name">
                              <b-input-group class="input-group-merge form-send-message mr-1">
                                <b-form-input
                                  autocomplete="off"
                                  v-model="form.author"
                                  placeholder="Enter Author Name"
                                  id="name"
                                  class="custom-field"
                                />
                              </b-input-group>
                              <small class="text-danger float-left">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col md="6" class="mb-2">
                          <validation-provider
                            #default="{ errors }"
                            name="author_title"
                            vid="author_title"
                            rules="required"
                          >
                            <b-form-group label="Author title*" label-for="author_title">
                              <b-input-group class="input-group-merge form-send-message mr-1">
                                <b-form-input
                                  autocomplete="off"
                                  v-model="form.author_title"
                                  placeholder="Enter Author title"
                                  id="name"
                                  class="custom-field"
                                />
                              </b-input-group>
                              <small class="text-danger float-left">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col md="6" class="mb-2">
                          <validation-provider
                            #default="{ errors }"
                            name="author name company"
                            vid="author_name_company"
                            rules="required"
                          >
                            <b-form-group label="Author Name Company*" label-for="author name company">
                              <b-input-group class="input-group-merge form-send-message mr-1">
                                <b-form-input
                                  autocomplete="off"
                                  v-model="form.HL_author"
                                  placeholder="Enter Author Name"
                                  id="name"
                                  class="custom-field"
                                />
                              </b-input-group>
                              <small class="text-danger float-left">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col md="6" class="mb-2">
                          <validation-provider
                            #default="{ errors }"
                            name="author_title_company"
                            vid="author_title_company"
                            rules="required"
                          >
                            <b-form-group label="Author title Company*" label-for="author title company">
                              <b-input-group class="input-group-merge form-send-message mr-1">
                                <b-form-input
                                  autocomplete="off"
                                  v-model="form.HL_author_title"
                                  placeholder="Enter Author title"
                                  id="name"
                                  class="custom-field"
                                />
                              </b-input-group>
                              <small class="text-danger float-left">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <b-col
                      md="6"
                      class="mb-2"
                      v-if="EditCertificateLogo || id == null || item.certificate_logo == null"
                    >
                      <b-form-group label="Certificate Logo" label-for="logo">
                        <b-form-file
                          id="log"
                          v-model="form.certificate_logo"
                          :state="Boolean(form.certificate_logo)"
                          accept="image/*"
                          class="custom-field"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="6" class="mb-2" v-if="!EditCertificateLogo && id != null && item.certificate_logo">
                      <div
                        class="d-flex align-items-center mb-1 cursor-pointer"
                        @click="EditCertificateLogo = true"
                      >
                        <feather-icon
                          :tooltip="'none'"
                          icon="EditIcon"
                          size="20"
                          class="cursor-pointer text-link mr-1"
                          v-b-tooltip.hover.top="'Edit Image'"
                        />
                        <small>Edit Certificate Logo</small>
                      </div>
                      <img :src="item.certificate_logo" alt width="100%" />
                    </b-col>
                    
              </b-row>
            </validation-observer>
            <div class="w-100 d-flex justify-content-center">
              <b-button
                variant="outline-secondary"
                class="rounded-pill mt-2 mr-1"
                @click="$router.go(-1)"
              >Cancel</b-button>
              <b-button
                variant="primary"
                :class="uploadProgress && uploadProgress != 100 ? 'rounded-pill mt-2 cursor-disabled' : 'rounded-pill mt-2'"
                type="submit"
                :disabled="uploadProgress && uploadProgress != 100 ? true : false"
              >Submit</b-button>
            </div>
          </b-form>
        </b-col>
        <b-col md="2"></b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BLink,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  VBTooltip,
  BForm,
  BFormTags,
  BToast,
  BProgress,
  BFormFile,
  BSpinner
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import "quill/dist/quill.core.css";
import { quillEditor } from "vue-quill-editor";


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BLink,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BCardBody,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    BFormFile,
    BToast,
    BProgress,
    BFormTags,
    vSelect,
    flatPickr,
    required,
    quillEditor,
    BSpinner
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },
  watch: {
    "form.course_id"(newVal) {
      if(this.checkFirst){
        this.form.modules_id = null
      }
      this.checkFirst = true
      this.$store.dispatch("bulk_certificates/modulesLookups",{course_id:this.form.course_id})
    },
  },
  computed: {
    ...mapGetters({
      item: "bulk_certificates/item",
      loadItem: "bulk_certificates/loadItem",
      modules: "bulk_certificates/modulesLookups",
      loadModules: "bulk_certificates/loadModules",
      courses: "bulk_certificates/coursesLookups",
      loadCourses: "bulk_certificates/loadCourses",
      uploadProgress: "app/uploadProgress",
    }),
    id() {
      return this.$route.params.id ? this.$route.params.id : null;
    },
    
  },
  data() {
    return {
      EditCertificateLogo: false,
      showToast: false,
      snowOption: {
        modules: {
          toolbar: "#toolbar"
        },
        theme: "snow"
      },
      checkFirst:true,
      form: {
        name: null,
        description:null,
        modules_id: null,
        description:null,
        course_id: null,
        certificate_logo: null,
        author:null,
        HL_author:null,
        author_title:null,
        HL_author_title:null,
      }
    };
  },
  methods: {
    init() {
      this.$store.dispatch("bulk_certificates/coursesLookups")
      
      if (this.id) {
        this.checkFirst = false;
          this.$store.dispatch("bulk_certificates/get", this.id).then(_ => {

            this.form.name = this.item.name;
            this.form.modules_id = this.item.modules_id;
            this.form.course_id = this.item.course_id;
            this.form.description = this.item.description;
            this.form.author = this.item.author;
            this.form.author_title = this.item.author_title;
            this.form.HL_author = this.item.HL_author;
            this.form.HL_author_title = this.item.HL_author_title;
            this.form.certificate_logo = this.item.certificate_logo
            
          });
        }
    },
    submit() {
      this.$refs.certificateForm.validate().then(success => {
        if (success) {
          this.showToast = true;
          if (this.id) {
            
            const formData = new FormData();
            for (const [key, value] of Object.entries(this.form)) {
              if (Array.isArray(value)) {
                value.forEach((item) => formData.append(`${key}[]`, item));
              } else {
                formData.append(key, value);
              }
            }
            
            const payload = { id: this.id, query: formData };
            this.$store
              .dispatch("bulk_certificates/update", payload)
              .then(res => {
                this.showToast = false;
                this.$router.go(-1);
              })
              .catch(error => {
                this.showToast = false;
                // if (error.response) {
                this.$swal({
                  icon: "error",
                  title: `<h4>${error.response.data.error}</h4>`,
                  showConfirmButton: true,
                  confirmButtonColor: "#E84185",
                  allowOutsideClick: true
                });
                // }
              });
          } else {
            const formData = new FormData();
            for (const [key, value] of Object.entries(this.form)) {
              if (Array.isArray(value)) {
                value.forEach((item) => formData.append(`${key}[]`, item));
              } else {
                formData.append(key, value);
              }
            }

            const payload = {query: formData };
            this.$store
              .dispatch("bulk_certificates/store", payload)
              .then(res => {
                this.showToast = false;
                this.$router.go(-1);
              })
              .catch(error => {
                this.showToast = false;
                if (error.response) {
                  this.$swal({
                    icon: "error",
                    title: `<h4>${error.response.data.message}</h4>`,
                    showConfirmButton: true,
                    confirmButtonColor: "#E84185",
                    allowOutsideClick: true
                  });
                }
              });
          }
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

[dir] .custom-file-label {
  border: unset !important;
  background: transparent !important;
  border-bottom: 1px solid #d8d6de !important;
  cursor: pointer !important;
}
input.is-invalid:focus ~ .custom-file-label {
  box-shadow: unset !important;
}

[dir] .custom-file-label::after {
  padding: 0.6rem 1rem !important;
  background-color: transparent !important;
  color: white !important;
}
.custom-file-label::after {
  border-radius: 0.357rem !important;
}

.custom-file-label::after {
  content: "" !important; /* Optional: Customize the text displayed on hover */
  cursor: pointer !important; /* Indicate clickable area */
  /* Add padding or other styles for a custom look */
}
</style>
