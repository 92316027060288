export default [
  {
    path: '/profiles',
    name: 'profiles',
    component: () => import('@/views/modules/users/Profile.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Profiles',
          active: true,
        },
      ],
    },
  },
  
]
