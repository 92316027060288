import {getAuth} from '@/auth/utils';
import store from '@/store'
export default [
  // {
  //   title: 'Admin Dashboard',
  //   icon: 'HomeIcon',
  //   route: { name: 'adminDashboard' },
  //   Permission: 'Admin dashboard'
  // },
  // {
  //   title: 'HOD Dashboard',
  //   icon: 'HomeIcon',
  //   route: { name: 'HODDashboard' },
  //   Permission: 'HOD dashboard'
  // },
  {
    title: 'Dashboard',
    icon: 'HomeIcon',
    route: { name: 'dashboard' },
    Permission: 'Public dashboard'
  },
  {
    title: 'Schools',
    icon: 'LayersIcon',
    route: { name: 'schools' },
    Permission: 'Access School'
  },
  {
    title: 'Learning',
    icon: 'BookIcon',
    route: { name: 'learning' }, 
    Permission: 'Access Learning'
    // Permission: getAuth().user.type != 2 ? 'Public' : ''
  },
  {
    title: 'Student Learning',
    icon: 'BookIcon',
    route: { name: 'student_learning' },
    Permission: 'Access Student Learning'
    // Permission: getAuth().user.type == 2 ? 'Public' : ''
  },
  {
    title: 'Charts',
    icon: 'PieChartIcon',
    route: { name: 'student_analytics' },
    Permission: 'Access Charts',
  },
  {
    title: 'Users',
    icon: 'UsersIcon',
    Permission:'Access Users',
    route: { name: 'users' },
  },
  {
    title: 'Students',
    icon: 'UsersIcon',
    Permission:'Access Students',
    route: { name: 'students' },
  },
  {
    title: 'Roles & Permissions',
    icon: 'KeyIcon',
    route: { name: 'roles' },
    Permission: 'Access Role'
  },
  {
    title: 'Requests',
    icon: 'FileTextIcon',
    route: { name: 'requests' },
    Permission: 'Access Requests'
  },
  {
    title: 'Admin',
    icon: 'SettingsIcon',
    Permission: 'View Admin',
    children: [
      {
        title: 'Courses',
        route: { name: 'courses' },
        icon: ''
      },
      {
        title: 'Certificates',
        route: { name: 'bulkCertificates' },
        icon: ''
      },
      {
        title: 'Modules',
        route: { name: 'modules' },
        icon: ''
      },
      {
        title: 'Topics',
        route: { name: 'topics' },
        icon: ''
      },
      
    ],
  },
  {
    title: 'Logout',
    icon: 'LogOutIcon',
    route: { name: 'logout' },
    Permission: 'Public',
  },
]
